import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, Injectable, Inject, EventEmitter, Component, ChangeDetectionStrategy, ViewChild, Input, Output, Directive, QueryList, ContentChildren, NgModule } from '@angular/core';
import { BehaviorSubject, Observable, isObservable, of, NEVER, mergeMap, from, tap, fromEvent, throwError, merge, Subject, takeUntil } from 'rxjs';
import { switchMap, map, take, filter } from 'rxjs/operators';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
"use strict";
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */

/**
 * Copied from angular/components/google-maps and updated for Yandex.Maps API.
 * {@link https://github.com/angular/components/blob/main/src/google-maps/map-event-manager.ts}
 */
/**
 * Manages event on a Yandex.Maps object, ensuring that events are added only when necessary.
 * @internal
 */
class EventManager {
  constructor(ngZone) {
    this.ngZone = ngZone;
    /**
     * Listeners that were added before the target was set.
     */
    this.pending = [];
    this.listeners = [];
    this.targetStream = new BehaviorSubject(undefined);
  }
  /**
   * Gets an observable that adds an event listener to the map when a consumer subscribes to it.
   * @param name
   */
  getLazyEmitter(name) {
    return this.targetStream.pipe(switchMap(target => {
      const observable = new Observable(observer => {
        // If the target hasn't been initialized yet, cache the observer, so it can be added later.
        if (!target) {
          this.pending.push({
            observable,
            observer
          });
          return undefined;
        }
        const callback = event => {
          const e = {
            event,
            target,
            ymaps
          };
          this.ngZone.run(() => observer.next(e));
        };
        const listener = target.events.add(name, callback);
        this.listeners.push({
          name,
          callback,
          manager: listener
        });
        // Unsubscribe function
        return () => listener.remove(name, callback);
      });
      return observable;
    }));
  }
  /**
   * Sets the current target that the manager should bind events to.
   * @param target
   */
  setTarget(target) {
    const currentTarget = this.targetStream.value;
    if (target === currentTarget) {
      return;
    }
    // Clear the listeners from the pre-existing target.
    if (currentTarget) {
      this.clearListeners();
      this.pending = [];
    }
    this.targetStream.next(target);
    // Add the listeners that were bound before the map was initialized.
    this.pending.forEach(subscriber => subscriber.observable.subscribe(subscriber.observer));
    this.pending = [];
  }
  /**
   * Destroys the manager and clears the event listeners.
   */
  destroy() {
    this.clearListeners();
    this.pending = [];
    this.targetStream.complete();
  }
  /**
   * Clears all currently-registered event listeners.
   */
  clearListeners() {
    this.listeners.forEach(listener => {
      const {
        name,
        callback,
        manager
      } = listener;
      manager.remove(name, callback);
    });
    this.listeners = [];
  }
}

/**
 * An injection token to provide a configuration.
 *
 * Use this token only if you want to implement some special logic.
 * Otherwise, use an `AngularYandexMapsModule.forRoot()` method in a root module.
 *
 * Please note that `YaApiLoaderService` is provided at the root level,
 * so it won't take your configuration unless this token is provided in the root module.
 *
 * If you want to provide the configuration in the module other than the root module, you have to provide `YaApiLoaderService`.
 * But keep in mind that starting from `v16.1.0` `YaApiLoaderService` supports the dynamic configuration,
 * if you provide this service it will lead to unexpected issues such as script duplications.
 *
 * ```ts
 * \@NgModule({
 *   imports: [AngularYandexMapsModule],
 *   providers: [
 *     YaApiLoaderService,
 *     {
 *       provide: YA_CONFIG,
 *       useValue: {
 *         apikey: null,
 *         lang: 'ru_RU',
 *       },
 *     },
 *   ],
 * })
 * export class HomeModule {}
 * ```
 */
const YA_CONFIG = new InjectionToken('YA_CONFIG', {
  factory: () => ({})
});

/**
 * Copied from Taiga UI.
 * https://github.com/taiga-family/taiga-ui/blob/eafb4f498ff57f4a21eebe74aee97a72b5e8d3f8/projects/cdk/observables/zone.ts#L19
 */
function enterZone(zone) {
  return source => new Observable(subscriber => source.subscribe({
    next: value => zone.run(() => subscriber.next(value)),
    error: error => zone.run(() => subscriber.error(error)),
    complete: () => zone.run(() => subscriber.complete())
  }));
}
function exitZone(zone) {
  return source => new Observable(subscriber => zone.runOutsideAngular(() => source.subscribe(subscriber)));
}

/**
 * The `YaApiLoader` service handles loading of Yandex.Maps API. Use it if you do not need `YaMapComponent`.
 *
 * Starting from `v16.1.0` the service supports dynamic configuration changes by subscribing on a `YaConfig` observable.
 * It stores global API objects in a local cache, and updates them in runtime if necessary.
 * That's why do not provide this service, it will break the synchronizations between the local cache and HTML scripts.
 *
 * ```ts
 * import { YaApiLoaderService } from 'angular8-yandex-maps';
 *
 * @Component()
 * export class AppComponent {
 *   constructor(private yaApiLoaderService: YaApiLoaderService) {
 *     // Don't forget to unsubscribe
 *     this.yaApiLoaderService.load()
 *       .subscribe(v => console.log(v))
 *   }
 * }
 *```
 */
class YaApiLoaderService {
  constructor(config, document, platformId, ngZone) {
    this.document = document;
    this.ngZone = ngZone;
    this.defaultConfig = {
      lang: 'ru_RU',
      version: '2.1'
    };
    this.config$ = new BehaviorSubject(this.defaultConfig);
    this.cache = new Map();
    this.isBrowser = isPlatformBrowser(platformId);
    if (!isObservable(config)) {
      config = of(config);
    }
    config.subscribe(config => {
      this.config$.next({
        ...this.defaultConfig,
        ...config
      });
    });
  }
  /**
   * Loads Yandex.Maps API.
   */
  load() {
    if (!this.isBrowser) {
      return NEVER;
    }
    return this.config$.pipe(
    // 3rd party libraries shouldn't be run in a zone.
    // Libraries run tons of different events (requestAnimationFrame, setTimeout, etc.).
    // We do not need to run change detection for these events from the library.
    // Exit from a zone here, so all components are also created outside a zone.
    exitZone(this.ngZone), mergeMap(config => {
      /**
       * We use a script source as a cache key, since there are a lot of parameters that affect the API.
       * We can't rely only on one parameter. E.g., an enterprise flag is changed, we need to reload the API.
       */
      const cacheKey = this.getScriptSource(config);
      const cache = this.cache.get(cacheKey) || {};
      /**
       * If it exists in the cache, return the ymaps from it,
       * since script.onload triggers only once.
       */
      if (cache.ymaps) {
        const apiObject = cache.ymaps;
        /**
         * ready confirms that the API and DOM are ready for use,
         * we can return of(window.ymaps), but calling ready is safer, I believe.
         */
        return from(apiObject.ready()).pipe(
        // Each nested operator should run outside the zone.
        // Refer to the comment above for the reason why we need to exit the zone.
        exitZone(this.ngZone),
        /**
         * Actually, we need to update it only if they are not equal,
         * it happens if we change the configuration which required new window.ymaps.
         */
        tap(() => window.ymaps = apiObject), map(() => apiObject));
      }
      let script = cache.script;
      /**
       * It's possible that we have a script, but do not have ymaps.
       * It happens if there are concurrent requests, and the first request creates the script,
       * while the second must just wait for the API to be loaded.
       */
      if (!script) {
        script = this.createScript(config);
        this.cache.set(cacheKey, {
          script
        });
        this.document.body.appendChild(script);
      }
      /**
       * The API throws an error on a script load if there is window.ymaps.
       * So before changing the configuration, delete the global object.
       */
      if (window.ymaps) {
        delete window.ymaps;
      }
      /**
       * window.ymaps is set explicitly via a script load event.
       */
      const load = fromEvent(script, 'load').pipe(switchMap(() => from(ymaps.ready())), tap(() => this.cache.set(cacheKey, {
        script,
        ymaps
      })), map(() => ymaps));
      const error = fromEvent(script, 'error').pipe(switchMap(throwError));
      return merge(load, error).pipe(
      // Each nested operator should run outside the zone.
      // Refer to the comment above for the reason why we need to exit the zone.
      exitZone(this.ngZone), take(1));
    }));
  }
  createScript(config) {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = this.getScriptSource(config);
    script.id = 'yandexMapsApiScript';
    script.async = true;
    script.defer = true;
    return script;
  }
  /**
   * Returns a script source from a config.
   * @param config parameters to add to a source
   * @example
   * // returns 'https://api-maps.yandex.ru/2.1/?apikey=658f67a2-fd77-42e9-b99e-2bd48c4ccad4&lang=en_US'
   * getScriptSource({ apikey: '658f67a2-fd77-42e9-b99e-2bd48c4ccad4', lang: 'en_US' })
   */
  getScriptSource(config) {
    const {
      enterprise,
      version,
      ...rest
    } = config;
    const params = this.convertConfigIntoQueryParams(rest);
    if (version === 'v3') {
      throw new Error('This package does not support the Yandex.Maps API v3. See https://www.npmjs.com/package/angular-yandex-maps-v3');
    }
    return `https://${enterprise ? 'enterprise.' : ''}api-maps.yandex.ru/${version}/?${params}`;
  }
  /**
   * Converts a config into query string parameters.
   * @param config object to convert
   * @example
   * // returns "lang=ru_RU&apikey=XXX"
   * convertIntoQueryParams({ lang: 'ru_RU', apikey: 'XXX' })
   */
  convertConfigIntoQueryParams(config) {
    return Object.entries(config).map(([key, value]) => `${key}=${value}`).join('&');
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaApiLoaderService,
      deps: [{
        token: YA_CONFIG
      }, {
        token: DOCUMENT
      }, {
        token: PLATFORM_ID
      }, {
        token: i0.NgZone
      }],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaApiLoaderService,
      providedIn: 'root'
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaApiLoaderService,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [YA_CONFIG]
    }]
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.NgZone
  }]
});

/**
 * Generates a random string based on Date in hexadecimal numeral system.
 * @example f175517fa4f3
 * @internal
 */
const generateRandomId = () => `f${Date.now().toString(16)}`;

/**
 * The `ya-map` component wraps `ymaps.Map` class from the Yandex.Maps API.
 * You can configure the map via the component's inputs.
 * Events can be bound using the outputs of the component.
 *
 * <example-url>https://stackblitz.com/edit/map-onload-event?embed=1&view=preview</example-url>
 *
 * ```html
 * <ya-map
 *   [center]="[55.751952, 37.600739]"
 *   [state]="{type: 'yandex#satellite'}"
 * ></ya-map>
 * ```
 */
class YaMapComponent {
  constructor(ngZone, yaApiLoaderService) {
    this.ngZone = ngZone;
    this.yaApiLoaderService = yaApiLoaderService;
    this.destroy$ = new Subject();
    this.eventManager = new EventManager(this.ngZone);
    this.map$ = new BehaviorSubject(null);
    /**
     * Geo coordinates of the map center. Default is `[0, 0]`.
     * Shorthand for `[state]="{ center: [0, 0] }"`.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#Map__param-state.center}
     */
    this.center = [0, 0];
    /**
     * Map zoom level. Default level is `10`.
     * Shorthand for `[state]="{ zoom: 10 }"`.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#Map__param-state.zoom}
     */
    this.zoom = 10;
    /**
     * Map instance is created. This event runs outside an Angular zone.
     */
    this.ready = new EventEmitter();
    /**
     * The start of a new smooth map movement.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-actionbegin}
     */
    this.actionbegin = this.eventManager.getLazyEmitter('actionbegin');
    /**
     * Event that occurs when an action step was prematurely stopped.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-actionbreak}
     */
    this.actionbreak = this.eventManager.getLazyEmitter('actionbreak');
    /**
     * The end of smooth map movement.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-actionend}
     */
    this.actionend = this.eventManager.getLazyEmitter('actionend');
    /**
     * The start of a new step of smooth movement.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-actiontick}
     */
    this.actiontick = this.eventManager.getLazyEmitter('actiontick');
    /**
     * The end of performing a step of smooth movement.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-actiontickcomplete}
     */
    this.actiontickcomplete = this.eventManager.getLazyEmitter('actiontickcomplete');
    /**
     * Closing the balloon.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-balloonclose}
     */
    this.balloonclose = this.eventManager.getLazyEmitter('balloonclose');
    /**
     * Opening a balloon on a map.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-balloonopen}
     */
    this.balloonopen = this.eventManager.getLazyEmitter('balloonopen');
    /**
     * Event for a change to the map viewport.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-boundschange}
     */
    this.boundschange = this.eventManager.getLazyEmitter('boundschange');
    /**
     * Single left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-click}
     */
    this.yaclick = this.eventManager.getLazyEmitter('click');
    /**
     * Calls the element's context menu.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-contextmenu}
     */
    this.yacontextmenu = this.eventManager.getLazyEmitter('contextmenu');
    /**
     * Double left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-dblclick}
     */
    this.yadblclick = this.eventManager.getLazyEmitter('dblclick');
    /**
     * The map was destroyed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-destroy}
     */
    this.destroy = this.eventManager.getLazyEmitter('destroy');
    /**
     * Closing the hint.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-hintclose}
     */
    this.hintclose = this.eventManager.getLazyEmitter('hintclose');
    /**
     * Opening a hint on a map.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-hintopen}
     */
    this.hintopen = this.eventManager.getLazyEmitter('hintopen');
    /**
     * Map margins changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-marginchange}
     */
    this.marginchange = this.eventManager.getLazyEmitter('marginchange');
    /**
     * Pressing the mouse button over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousedown}
     */
    this.yamousedown = this.eventManager.getLazyEmitter('mousedown');
    /**
     * Pointing the cursor at the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseenter}
     */
    this.yamouseenter = this.eventManager.getLazyEmitter('mouseenter');
    /**
     * Moving the cursor off of the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseleave}
     */
    this.yamouseleave = this.eventManager.getLazyEmitter('mouseleave');
    /**
     * Moving the cursor over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousemove}
     */
    this.yamousemove = this.eventManager.getLazyEmitter('mousemove');
    /**
     * Letting go of the mouse button over an object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseup}
     */
    this.yamouseup = this.eventManager.getLazyEmitter('mouseup');
    /**
     * End of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchend}
     */
    this.multitouchend = this.eventManager.getLazyEmitter('multitouchend');
    /**
     * Repeating event during multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchmove}
     */
    this.multitouchmove = this.eventManager.getLazyEmitter('multitouchmove');
    /**
     * Start of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchstart}
     */
    this.multitouchstart = this.eventManager.getLazyEmitter('multitouchstart');
    /**
     * Map options changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-optionschange}
     */
    this.optionschange = this.eventManager.getLazyEmitter('optionschange');
    /**
     * Map size changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-sizechange}
     */
    this.sizechange = this.eventManager.getLazyEmitter('sizechange');
    /**
     * The map type changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Map.html#event_detail__event-typechange}
     */
    this.typechange = this.eventManager.getLazyEmitter('typechange');
    /**
     * Mouse wheel scrolling.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-wheel}
     */
    this.yawheel = this.eventManager.getLazyEmitter('wheel');
  }
  /**
   * Handles input changes and passes them in API.
   * @param changes
   */
  ngOnChanges(changes) {
    // It must be run outside a zone; otherwise, all async events within this call will cause ticks.
    this.ngZone.runOutsideAngular(() => {
      const map = this.map$.value;
      if (map) {
        const {
          center,
          zoom,
          state,
          options
        } = changes;
        if (state) {
          this.setState(this.combineState(), map);
        }
        if (center) {
          map.setCenter(center.currentValue);
        }
        if (zoom) {
          map.setZoom(zoom.currentValue);
        }
        if (options) {
          map.options.set(options.currentValue);
        }
      }
    });
  }
  ngAfterViewInit() {
    this.yaApiLoaderService.load().pipe(takeUntil(this.destroy$)).subscribe(() => {
      const id = generateRandomId();
      const map = this.createMap(id);
      /**
       * Once the configuration is changed, e.g. language,
       * we need to reinitialize the map.
       */
      if (this.map$.value) {
        this.map$.value.destroy();
      }
      this.map$.next(map);
      this.eventManager.setTarget(map);
      this.ready.emit({
        ymaps,
        target: map
      });
    });
  }
  ngOnDestroy() {
    this.eventManager.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Destructs state and passes it in API.
   * @param state
   * @param map
   */
  setState(state, map) {
    const {
      behaviors,
      bounds,
      center,
      controls,
      margin,
      type,
      zoom
    } = state;
    if (behaviors) {
      map.behaviors.enable(behaviors);
    }
    if (bounds) {
      map.setBounds(bounds);
    }
    if (center) {
      map.setCenter(center);
    }
    if (controls) {
      controls.forEach(control => map.controls.add(control));
    }
    if (margin) {
      map.margin.setDefaultMargin(margin);
    }
    if (type) {
      map.setType(type);
    }
    if (zoom) {
      map.setZoom(zoom);
    }
  }
  /**
   * Creates a map.
   * @param id ID which will be set to the map container.
   */
  createMap(id) {
    const containerElem = this.container.nativeElement;
    containerElem.setAttribute('id', id);
    containerElem.style.cssText = 'width: 100%; height: 100%;';
    return new ymaps.Map(id, this.combineState(), this.options || {});
  }
  /**
   * Combines the center and zoom into single object.
   */
  combineState() {
    const state = this.state || {};
    return {
      ...state,
      center: this.center || state.center || [0, 0],
      zoom: this.zoom ?? state.zoom ?? 10
    };
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaMapComponent,
      deps: [{
        token: i0.NgZone
      }, {
        token: YaApiLoaderService
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.0.1",
      type: YaMapComponent,
      selector: "ya-map",
      inputs: {
        center: "center",
        zoom: "zoom",
        state: "state",
        options: "options"
      },
      outputs: {
        ready: "ready",
        actionbegin: "actionbegin",
        actionbreak: "actionbreak",
        actionend: "actionend",
        actiontick: "actiontick",
        actiontickcomplete: "actiontickcomplete",
        balloonclose: "balloonclose",
        balloonopen: "balloonopen",
        boundschange: "boundschange",
        yaclick: "yaclick",
        yacontextmenu: "yacontextmenu",
        yadblclick: "yadblclick",
        destroy: "destroy",
        hintclose: "hintclose",
        hintopen: "hintopen",
        marginchange: "marginchange",
        yamousedown: "yamousedown",
        yamouseenter: "yamouseenter",
        yamouseleave: "yamouseleave",
        yamousemove: "yamousemove",
        yamouseup: "yamouseup",
        multitouchend: "multitouchend",
        multitouchmove: "multitouchmove",
        multitouchstart: "multitouchstart",
        optionschange: "optionschange",
        sizechange: "sizechange",
        typechange: "typechange",
        yawheel: "yawheel"
      },
      viewQueries: [{
        propertyName: "container",
        first: true,
        predicate: ["container"],
        descendants: true
      }],
      usesOnChanges: true,
      ngImport: i0,
      template: '<div #container></div>',
      isInline: true,
      changeDetection: i0.ChangeDetectionStrategy.OnPush
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaMapComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ya-map',
      template: '<div #container></div>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }],
  ctorParameters: () => [{
    type: i0.NgZone
  }, {
    type: YaApiLoaderService
  }],
  propDecorators: {
    container: [{
      type: ViewChild,
      args: ['container']
    }],
    center: [{
      type: Input
    }],
    zoom: [{
      type: Input
    }],
    state: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    actionbegin: [{
      type: Output
    }],
    actionbreak: [{
      type: Output
    }],
    actionend: [{
      type: Output
    }],
    actiontick: [{
      type: Output
    }],
    actiontickcomplete: [{
      type: Output
    }],
    balloonclose: [{
      type: Output
    }],
    balloonopen: [{
      type: Output
    }],
    boundschange: [{
      type: Output
    }],
    yaclick: [{
      type: Output
    }],
    yacontextmenu: [{
      type: Output
    }],
    yadblclick: [{
      type: Output
    }],
    destroy: [{
      type: Output
    }],
    hintclose: [{
      type: Output
    }],
    hintopen: [{
      type: Output
    }],
    marginchange: [{
      type: Output
    }],
    yamousedown: [{
      type: Output
    }],
    yamouseenter: [{
      type: Output
    }],
    yamouseleave: [{
      type: Output
    }],
    yamousemove: [{
      type: Output
    }],
    yamouseup: [{
      type: Output
    }],
    multitouchend: [{
      type: Output
    }],
    multitouchmove: [{
      type: Output
    }],
    multitouchstart: [{
      type: Output
    }],
    optionschange: [{
      type: Output
    }],
    sizechange: [{
      type: Output
    }],
    typechange: [{
      type: Output
    }],
    yawheel: [{
      type: Output
    }]
  }
});

/**
 * The `ya-geoobject` component wraps `ymaps.GeoObject` class from the Yandex.Maps API.
 * You can configure it via the component's inputs.
 * Events can be bound using the outputs of the component.
 *
 * <example-url>https://stackblitz.com/edit/geoobject-polygon?embed=1&view=preview</example-url>
 *
 * ```html
 * <ya-map [center]="[55.761952, 37.620739]">
 *   <ya-geoobject
 *     [feature]="{ geometry: { type: 'Rectangle', coordinates: [[55.665, 37.66], [55.64,37.53]] } }"
 *   ></ya-geoobject>
 * </ya-map>
 * ```
 */
class YaGeoObjectDirective {
  constructor(ngZone, yaMapComponent) {
    this.ngZone = ngZone;
    this.yaMapComponent = yaMapComponent;
    this.destroy$ = new Subject();
    this.eventManager = new EventManager(this.ngZone);
    /**
     * Geo object instance is added to a Map. This event runs outside an Angular zone.
     */
    this.ready = new EventEmitter();
    /**
     * Closing the balloon.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-balloonclose}
     */
    this.balloonclose = this.eventManager.getLazyEmitter('balloonclose');
    /**
     * Opening a balloon on a geo object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-balloonopen}
     */
    this.balloonopen = this.eventManager.getLazyEmitter('balloonopen');
    /**
     * Event preceding the "drag" event.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-beforedrag}
     */
    this.beforedrag = this.eventManager.getLazyEmitter('beforedrag');
    /**
     * Event preceding the "dragstart" event.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-beforedragstart}
     */
    this.beforedragstart = this.eventManager.getLazyEmitter('beforedragstart');
    /**
     * Single left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-click}
     */
    this.yaclick = this.eventManager.getLazyEmitter('click');
    /**
     * Calls the element's context menu.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-contextmenu}
     */
    this.yacontextmenu = this.eventManager.getLazyEmitter('contextmenu');
    /**
     * Double left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-dblclick}
     */
    this.yadblclick = this.eventManager.getLazyEmitter('dblclick');
    /**
     * Dragging a geo object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-drag}
     */
    this.yadrag = this.eventManager.getLazyEmitter('drag');
    /**
     * End of geo object dragging.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-dragend}
     */
    this.yadragend = this.eventManager.getLazyEmitter('dragend');
    /**
     * Start of geo object dragging.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-dragstart}
     */
    this.yadragstart = this.eventManager.getLazyEmitter('dragstart');
    /**
     * Change in the state of the editor for the geo object's geometry.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-editorstatechange}
     */
    this.editorstatechange = this.eventManager.getLazyEmitter('editorstatechange');
    /**
     * Change to the geo object geometry.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-geometrychange}
     */
    this.geometrychange = this.eventManager.getLazyEmitter('geometrychange');
    /**
     * Closing the hint.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-hintclose}
     */
    this.hintclose = this.eventManager.getLazyEmitter('hintclose');
    /**
     * Opening a hint on a geo object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-hintopen}
     */
    this.hintopen = this.eventManager.getLazyEmitter('hintopen');
    /**
     * Map reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IParentOnMap.html#event_detail__event-mapchange}
     */
    this.mapchange = this.eventManager.getLazyEmitter('mapchange');
    /**
     * Pressing the mouse button over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousedown}
     */
    this.yamousedown = this.eventManager.getLazyEmitter('mousedown');
    /**
     * Pointing the cursor at the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseenter}
     */
    this.yamouseenter = this.eventManager.getLazyEmitter('mouseenter');
    /**
     * Moving the cursor off of the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseleave}
     */
    this.yamouseleave = this.eventManager.getLazyEmitter('mouseleave');
    /**
     * Moving the cursor over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousemove}
     */
    this.yamousemove = this.eventManager.getLazyEmitter('mousemove');
    /**
     * Letting go of the mouse button over an object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseup}
     */
    this.yamouseup = this.eventManager.getLazyEmitter('mouseup');
    /**
     * End of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchend}
     */
    this.multitouchend = this.eventManager.getLazyEmitter('multitouchend');
    /**
     * Repeating event during multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchmove}
     */
    this.multitouchmove = this.eventManager.getLazyEmitter('multitouchmove');
    /**
     * Start of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchstart}
     */
    this.multitouchstart = this.eventManager.getLazyEmitter('multitouchstart');
    /**
     * Change to the object options.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/ICustomizable.html#event_detail__event-optionschange}
     */
    this.optionschange = this.eventManager.getLazyEmitter('optionschange');
    /**
     * Change to the geo object overlay.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-overlaychange}
     */
    this.overlaychange = this.eventManager.getLazyEmitter('overlaychange');
    /**
     * The parent object reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IChild.html#event_detail__event-parentchange}
     */
    this.parentchange = this.eventManager.getLazyEmitter('parentchange');
    /**
     * Change to the geo object data.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-propertieschange}
     */
    this.propertieschange = this.eventManager.getLazyEmitter('propertieschange');
    /**
     * Mouse wheel scrolling.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-wheel}
     */
    this.yawheel = this.eventManager.getLazyEmitter('wheel');
  }
  /**
   * Handles input changes and passes them in API.
   * @param changes
   */
  ngOnChanges(changes) {
    // It must be run outside a zone; otherwise, all async events within this call will cause ticks.
    this.ngZone.runOutsideAngular(() => {
      const {
        geoObject
      } = this;
      if (geoObject) {
        const {
          feature,
          options
        } = changes;
        if (feature) {
          this.setFeature(feature.currentValue, geoObject);
        }
        if (options) {
          geoObject.options.set(options.currentValue);
        }
      }
    });
  }
  ngOnInit() {
    this.yaMapComponent.map$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(map => {
      const geoObject = this.createGeoObject();
      this.geoObject = geoObject;
      map.geoObjects.add(geoObject);
      this.eventManager.setTarget(geoObject);
      this.ready.emit({
        ymaps,
        target: geoObject
      });
    });
  }
  ngOnDestroy() {
    if (this.geoObject) {
      this.yaMapComponent?.map$.value?.geoObjects.remove(this.geoObject);
      this.eventManager.destroy();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Destructs feature and passes it in API.
   * @param feature
   * @param geoObject
   */
  setFeature(feature, geoObject) {
    const {
      geometry,
      properties
    } = feature;
    if (geometry) {
      console.warn('The geometry can not be changed after entity init. To set it, you should recreate a GeoObject with new feature.geometry');
    }
    if (properties) {
      geoObject.properties.set(properties);
    }
  }
  /**
   * Creates GeoObject.
   */
  createGeoObject() {
    return new ymaps.GeoObject(this.feature, this.options);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaGeoObjectDirective,
      deps: [{
        token: i0.NgZone
      }, {
        token: YaMapComponent
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.0.1",
      type: YaGeoObjectDirective,
      selector: "ya-geoobject",
      inputs: {
        feature: "feature",
        options: "options"
      },
      outputs: {
        ready: "ready",
        balloonclose: "balloonclose",
        balloonopen: "balloonopen",
        beforedrag: "beforedrag",
        beforedragstart: "beforedragstart",
        yaclick: "yaclick",
        yacontextmenu: "yacontextmenu",
        yadblclick: "yadblclick",
        yadrag: "yadrag",
        yadragend: "yadragend",
        yadragstart: "yadragstart",
        editorstatechange: "editorstatechange",
        geometrychange: "geometrychange",
        hintclose: "hintclose",
        hintopen: "hintopen",
        mapchange: "mapchange",
        yamousedown: "yamousedown",
        yamouseenter: "yamouseenter",
        yamouseleave: "yamouseleave",
        yamousemove: "yamousemove",
        yamouseup: "yamouseup",
        multitouchend: "multitouchend",
        multitouchmove: "multitouchmove",
        multitouchstart: "multitouchstart",
        optionschange: "optionschange",
        overlaychange: "overlaychange",
        parentchange: "parentchange",
        propertieschange: "propertieschange",
        yawheel: "yawheel"
      },
      usesOnChanges: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaGeoObjectDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ya-geoobject'
    }]
  }],
  ctorParameters: () => [{
    type: i0.NgZone
  }, {
    type: YaMapComponent
  }],
  propDecorators: {
    feature: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    balloonclose: [{
      type: Output
    }],
    balloonopen: [{
      type: Output
    }],
    beforedrag: [{
      type: Output
    }],
    beforedragstart: [{
      type: Output
    }],
    yaclick: [{
      type: Output
    }],
    yacontextmenu: [{
      type: Output
    }],
    yadblclick: [{
      type: Output
    }],
    yadrag: [{
      type: Output
    }],
    yadragend: [{
      type: Output
    }],
    yadragstart: [{
      type: Output
    }],
    editorstatechange: [{
      type: Output
    }],
    geometrychange: [{
      type: Output
    }],
    hintclose: [{
      type: Output
    }],
    hintopen: [{
      type: Output
    }],
    mapchange: [{
      type: Output
    }],
    yamousedown: [{
      type: Output
    }],
    yamouseenter: [{
      type: Output
    }],
    yamouseleave: [{
      type: Output
    }],
    yamousemove: [{
      type: Output
    }],
    yamouseup: [{
      type: Output
    }],
    multitouchend: [{
      type: Output
    }],
    multitouchmove: [{
      type: Output
    }],
    multitouchstart: [{
      type: Output
    }],
    optionschange: [{
      type: Output
    }],
    overlaychange: [{
      type: Output
    }],
    parentchange: [{
      type: Output
    }],
    propertieschange: [{
      type: Output
    }],
    yawheel: [{
      type: Output
    }]
  }
});

/**
 * The `ya-placemark` directive wraps `ymaps.Placemark` class from the Yandex.Maps API.
 * You can configure it via the directive's inputs.
 * Events can be bound using the outputs of the directive.
 *
 * <example-url>https://stackblitz.com/edit/custom-placemark?embed=1&view=preview</example-url>
 *
 * ```html
 * <ya-map [center]="[55.751952, 37.600739]">
 *   <ya-placemark [geometry]="[55.751952, 37.600739]"></ya-placemark>
 * </ya-map>
 * ```
 */
class YaPlacemarkDirective {
  constructor(ngZone, yaMapComponent) {
    this.ngZone = ngZone;
    this.yaMapComponent = yaMapComponent;
    this.destroy$ = new Subject();
    this.eventManager = new EventManager(this.ngZone);
    /**
     * Coordinates of the placemark, or a hash describing the geometry, or a reference to the point geometry object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Placemark.html#Placemark__param-geometry}
     */
    this.geometry = [];
    /**
     * Placemark instance is added to a Map. This event runs outside an Angular zone.
     */
    this.ready = new EventEmitter();
    /**
     * Closing the balloon.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-balloonclose}
     */
    this.balloonclose = this.eventManager.getLazyEmitter('balloonclose');
    /**
     * Opening a balloon on a geo object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-balloonopen}
     */
    this.balloonopen = this.eventManager.getLazyEmitter('balloonopen');
    /**
     * Event preceding the "drag" event.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-beforedrag}
     */
    this.beforedrag = this.eventManager.getLazyEmitter('beforedrag');
    /**
     * Event preceding the "dragstart" event.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-beforedragstart}
     */
    this.beforedragstart = this.eventManager.getLazyEmitter('beforedragstart');
    /**
     * Single left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-click}
     */
    this.yaclick = this.eventManager.getLazyEmitter('click');
    /**
     * Calls the element's context menu.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-contextmenu}
     */
    this.yacontextmenu = this.eventManager.getLazyEmitter('contextmenu');
    /**
     * Double left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-dblclick}
     */
    this.yadblclick = this.eventManager.getLazyEmitter('dblclick');
    /**
     * Dragging a geo object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-drag}
     */
    this.yadrag = this.eventManager.getLazyEmitter('drag');
    /**
     * End of geo object dragging.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-dragend}
     */
    this.yadragend = this.eventManager.getLazyEmitter('dragend');
    /**
     * Start of geo object dragging.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-dragstart}
     */
    this.yadragstart = this.eventManager.getLazyEmitter('dragstart');
    /**
     * Change in the state of the editor for the geo object's geometry.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-editorstatechange}
     */
    this.editorstatechange = this.eventManager.getLazyEmitter('editorstatechange');
    /**
     * Change to the geo object geometry.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-geometrychange}
     */
    this.geometrychange = this.eventManager.getLazyEmitter('geometrychange');
    /**
     * Closing the hint.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-hintclose}
     */
    this.hintclose = this.eventManager.getLazyEmitter('hintclose');
    /**
     * Opening a hint on a geo object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/GeoObject.html#event_detail__event-hintopen}
     */
    this.hintopen = this.eventManager.getLazyEmitter('hintopen');
    /**
     * Map reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IParentOnMap.html#event_detail__event-mapchange}
     */
    this.mapchange = this.eventManager.getLazyEmitter('mapchange');
    /**
     * Pressing the mouse button over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousedown}
     */
    this.yamousedown = this.eventManager.getLazyEmitter('mousedown');
    /**
     * Pointing the cursor at the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseenter}
     */
    this.yamouseenter = this.eventManager.getLazyEmitter('mouseenter');
    /**
     * Moving the cursor off of the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseleave}
     */
    this.yamouseleave = this.eventManager.getLazyEmitter('mouseleave');
    /**
     * Moving the cursor over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousemove}
     */
    this.yamousemove = this.eventManager.getLazyEmitter('mousemove');
    /**
     * Letting go of the mouse button over an object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseup}
     */
    this.yamouseup = this.eventManager.getLazyEmitter('mouseup');
    /**
     * End of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchend}
     */
    this.multitouchend = this.eventManager.getLazyEmitter('multitouchend');
    /**
     * Repeating event during multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchmove}
     */
    this.multitouchmove = this.eventManager.getLazyEmitter('multitouchmove');
    /**
     * Start of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchstart}
     */
    this.multitouchstart = this.eventManager.getLazyEmitter('multitouchstart');
    /**
     * Change to the object options.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/ICustomizable.html#event_detail__event-optionschange}
     */
    this.optionschange = this.eventManager.getLazyEmitter('optionschange');
    /**
     * Change to the geo object overlay.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-overlaychange}
     */
    this.overlaychange = this.eventManager.getLazyEmitter('overlaychange');
    /**
     * The parent object reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IChild.html#event_detail__event-parentchange}
     */
    this.parentchange = this.eventManager.getLazyEmitter('parentchange');
    /**
     * Change to the geo object data.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-propertieschange}
     */
    this.propertieschange = this.eventManager.getLazyEmitter('propertieschange');
    /**
     * Mouse wheel scrolling.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-wheel}
     */
    this.yawheel = this.eventManager.getLazyEmitter('wheel');
  }
  /**
   * Handles input changes and passes them in API.
   * @param changes
   */
  ngOnChanges(changes) {
    // It must be run outside a zone; otherwise, all async events within this call will cause ticks.
    this.ngZone.runOutsideAngular(() => {
      const {
        placemark
      } = this;
      if (placemark) {
        const {
          geometry,
          properties,
          options
        } = changes;
        if (geometry) {
          placemark.geometry?.setCoordinates(geometry.currentValue);
        }
        if (properties) {
          placemark.properties.set(properties.currentValue);
        }
        if (options) {
          placemark.options.set(options.currentValue);
        }
      }
    });
  }
  ngOnInit() {
    this.yaMapComponent.map$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(map => {
      const placemark = this.createPlacemark();
      this.placemark = placemark;
      map.geoObjects.add(placemark);
      this.eventManager.setTarget(placemark);
      this.ready.emit({
        ymaps,
        target: placemark
      });
    });
  }
  ngOnDestroy() {
    if (this.placemark) {
      this.yaMapComponent?.map$.value?.geoObjects.remove(this.placemark);
      this.eventManager.destroy();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Creates a placemark.
   */
  createPlacemark() {
    return new ymaps.Placemark(this.geometry, this.properties, this.options);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaPlacemarkDirective,
      deps: [{
        token: i0.NgZone
      }, {
        token: YaMapComponent
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.0.1",
      type: YaPlacemarkDirective,
      selector: "ya-placemark",
      inputs: {
        geometry: "geometry",
        properties: "properties",
        options: "options"
      },
      outputs: {
        ready: "ready",
        balloonclose: "balloonclose",
        balloonopen: "balloonopen",
        beforedrag: "beforedrag",
        beforedragstart: "beforedragstart",
        yaclick: "yaclick",
        yacontextmenu: "yacontextmenu",
        yadblclick: "yadblclick",
        yadrag: "yadrag",
        yadragend: "yadragend",
        yadragstart: "yadragstart",
        editorstatechange: "editorstatechange",
        geometrychange: "geometrychange",
        hintclose: "hintclose",
        hintopen: "hintopen",
        mapchange: "mapchange",
        yamousedown: "yamousedown",
        yamouseenter: "yamouseenter",
        yamouseleave: "yamouseleave",
        yamousemove: "yamousemove",
        yamouseup: "yamouseup",
        multitouchend: "multitouchend",
        multitouchmove: "multitouchmove",
        multitouchstart: "multitouchstart",
        optionschange: "optionschange",
        overlaychange: "overlaychange",
        parentchange: "parentchange",
        propertieschange: "propertieschange",
        yawheel: "yawheel"
      },
      usesOnChanges: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaPlacemarkDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ya-placemark'
    }]
  }],
  ctorParameters: () => [{
    type: i0.NgZone
  }, {
    type: YaMapComponent
  }],
  propDecorators: {
    geometry: [{
      type: Input
    }],
    properties: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    balloonclose: [{
      type: Output
    }],
    balloonopen: [{
      type: Output
    }],
    beforedrag: [{
      type: Output
    }],
    beforedragstart: [{
      type: Output
    }],
    yaclick: [{
      type: Output
    }],
    yacontextmenu: [{
      type: Output
    }],
    yadblclick: [{
      type: Output
    }],
    yadrag: [{
      type: Output
    }],
    yadragend: [{
      type: Output
    }],
    yadragstart: [{
      type: Output
    }],
    editorstatechange: [{
      type: Output
    }],
    geometrychange: [{
      type: Output
    }],
    hintclose: [{
      type: Output
    }],
    hintopen: [{
      type: Output
    }],
    mapchange: [{
      type: Output
    }],
    yamousedown: [{
      type: Output
    }],
    yamouseenter: [{
      type: Output
    }],
    yamouseleave: [{
      type: Output
    }],
    yamousemove: [{
      type: Output
    }],
    yamouseup: [{
      type: Output
    }],
    multitouchend: [{
      type: Output
    }],
    multitouchmove: [{
      type: Output
    }],
    multitouchstart: [{
      type: Output
    }],
    optionschange: [{
      type: Output
    }],
    overlaychange: [{
      type: Output
    }],
    parentchange: [{
      type: Output
    }],
    propertieschange: [{
      type: Output
    }],
    yawheel: [{
      type: Output
    }]
  }
});

/**
 * The `ya-clusterer` component wraps `ymaps.Clusterer` class from the Yandex.Maps API.
 * You can configure it via the component's inputs.
 * Events can be bound using the outputs of the component.
 *
 * <example-url>https://stackblitz.com/edit/placemark-clusterer?embed=1&view=preview</example-url>
 *
 * ```html
 * <ya-map [center]="[55.761952, 37.620739]">
 *   <ya-clusterer [options]="{ minClusterSize: 5 }">
 *     <ya-placemark [geometry]="[55.74, 37.5]"></ya-placemark>
 *     <ya-placemark [geometry]="[55.64, 37.46]"></ya-placemark>
 *     <ya-placemark [geometry]="[55.75, 37.38]"></ya-placemark>
 *
 *     <ya-geoobject
 *       [feature]="{ geometry: { type: 'Point', coordinates: [55.81, 37.4] } }"
 *      ></ya-geoobject>
 *
 *     <ya-geoobject
 *       [feature]="{ geometry: { type: 'Point', coordinates: [55.7, 37.39] } }"
 *      ></ya-geoobject>
 *   </ya-clusterer>
 * </ya-map>
 * ```
 */
class YaClustererComponent {
  constructor(ngZone, yaMapComponent) {
    this.ngZone = ngZone;
    this.yaMapComponent = yaMapComponent;
    this.placemarks = new QueryList();
    this.geoObjects = new QueryList();
    this.destroy$ = new Subject();
    this.eventManager = new EventManager(this.ngZone);
    /**
     * Clusterer instance is added to a Map. This event runs outside an Angular zone.
     */
    this.ready = new EventEmitter();
    /**
     * Closing the hint.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Clusterer.html#event_detail__event-hintclose}
     */
    this.hintclose = this.eventManager.getLazyEmitter('hintclose');
    /**
     * Opening a hint on a map.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/Clusterer.html#event_detail__event-hintopen}
     */
    this.hintopen = this.eventManager.getLazyEmitter('hintopen');
    /**
     * Map reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IParentOnMap.html#event_detail__event-mapchange}
     */
    this.mapchange = this.eventManager.getLazyEmitter('mapchange');
    /**
     * Change to the object options.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/ICustomizable.html#event_detail__event-optionschange}
     */
    this.optionschange = this.eventManager.getLazyEmitter('optionschange');
    /**
     * The parent object reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IChild.html#event_detail__event-parentchange}
     */
    this.parentchange = this.eventManager.getLazyEmitter('parentchange');
  }
  /**
   * Handles input changes and passes them in API.
   * @param changes
   */
  ngOnChanges(changes) {
    // It must be run outside a zone; otherwise, all async events within this call will cause ticks.
    this.ngZone.runOutsideAngular(() => {
      const {
        clusterer
      } = this;
      if (clusterer) {
        const {
          options
        } = changes;
        if (options) {
          clusterer.options.set(options.currentValue);
        }
      }
    });
  }
  ngAfterContentInit() {
    this.yaMapComponent.map$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(map => {
      const clusterer = this.createClusterer();
      this.clusterer = clusterer;
      map.geoObjects.add(clusterer);
      this.eventManager.setTarget(clusterer);
      this.watchForPlacemarkChanges(clusterer);
      this.watchForGeoObjectChanges(clusterer);
      this.ready.emit({
        ymaps,
        target: clusterer
      });
    });
  }
  ngOnDestroy() {
    this.eventManager.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Creates Clusterer.
   */
  createClusterer() {
    return new ymaps.Clusterer(this.options);
  }
  watchForPlacemarkChanges(clusterer) {
    // Adds new Placemarks to the clusterer on changes.
    const initialPlacemarks = this.getInternalPlacemarks(this.placemarks.toArray());
    const currentPlacemarks = new Set(initialPlacemarks);
    clusterer.add(initialPlacemarks);
    this.placemarks.changes.pipe(takeUntil(this.destroy$)).subscribe(placemarkDirectives => {
      const newPlacemarks = new Set(this.getInternalPlacemarks(placemarkDirectives));
      const difference = this.getDifference(newPlacemarks, currentPlacemarks);
      clusterer.add(difference.toAdd);
      clusterer.remove(difference.toRemove);
    });
  }
  watchForGeoObjectChanges(clusterer) {
    // Adds new GeoObjects to the clusterer on changes.
    const initialGeoObjects = this.getInternalGeoObjects(this.geoObjects.toArray());
    const currentGeoObjects = new Set(initialGeoObjects);
    clusterer.add(initialGeoObjects);
    this.geoObjects.changes.pipe(takeUntil(this.destroy$)).subscribe(geoObjectDirectives => {
      const newGeoObjects = new Set(this.getInternalGeoObjects(geoObjectDirectives));
      const difference = this.getDifference(newGeoObjects, currentGeoObjects);
      clusterer.add(difference.toAdd);
      clusterer.remove(difference.toRemove);
    });
  }
  /**
   * Determines what should be added/removed in current set to equal new set
   *
   * @param newSet
   * @param currentSet
   */
  getDifference(newSet, currentSet) {
    const toAdd = [];
    const toRemove = [];
    newSet.forEach(component => {
      if (!currentSet.has(component)) {
        toAdd.push(component);
        currentSet.add(component);
      }
    });
    currentSet.forEach(component => {
      if (!newSet.has(component)) {
        toRemove.push(component);
        currentSet.delete(component);
      }
    });
    return {
      toAdd,
      toRemove
    };
  }
  getInternalPlacemarks(placemarks) {
    return placemarks.filter(component => !!component.placemark).map(component => component.placemark);
  }
  getInternalGeoObjects(geoObjects) {
    return geoObjects.filter(component => !!component.geoObject).map(component => component.geoObject);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaClustererComponent,
      deps: [{
        token: i0.NgZone
      }, {
        token: YaMapComponent
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "18.0.1",
      type: YaClustererComponent,
      selector: "ya-clusterer",
      inputs: {
        options: "options"
      },
      outputs: {
        ready: "ready",
        hintclose: "hintclose",
        hintopen: "hintopen",
        mapchange: "mapchange",
        optionschange: "optionschange",
        parentchange: "parentchange"
      },
      queries: [{
        propertyName: "placemarks",
        predicate: YaPlacemarkDirective
      }, {
        propertyName: "geoObjects",
        predicate: YaGeoObjectDirective
      }],
      usesOnChanges: true,
      ngImport: i0,
      template: '<ng-content></ng-content>',
      isInline: true,
      changeDetection: i0.ChangeDetectionStrategy.OnPush
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaClustererComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ya-clusterer',
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }],
  ctorParameters: () => [{
    type: i0.NgZone
  }, {
    type: YaMapComponent
  }],
  propDecorators: {
    placemarks: [{
      type: ContentChildren,
      args: [YaPlacemarkDirective]
    }],
    geoObjects: [{
      type: ContentChildren,
      args: [YaGeoObjectDirective]
    }],
    options: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    hintclose: [{
      type: Output
    }],
    hintopen: [{
      type: Output
    }],
    mapchange: [{
      type: Output
    }],
    optionschange: [{
      type: Output
    }],
    parentchange: [{
      type: Output
    }]
  }
});

/**
 * The `ya-control` component wraps `ymaps.control[YaControlType]` classes from the Yandex.Maps API.
 * You can configure `ymaps.control[YaControlType]` via the component's inputs.
 * API Events can be bound only manually. You can use `ready` event to get an instance.
 *
 * <example-url>https://stackblitz.com/edit/searchcontrol?embed=1&view=preview</example-url>
 *
 * ```html
 * <ya-map [center]="[55.761952, 37.620739]">
 *   <ya-control
 *     type="RoutePanel"
 *     [parameters]="{ options: { float: 'right' } }"
 *   ></ya-control>
 * </ya-map>
 * ```
 */
class YaControlDirective {
  constructor(yaMapComponent) {
    this.yaMapComponent = yaMapComponent;
    this.destroy$ = new Subject();
    /**
     * Control instance is added to a Map. This event runs outside an Angular zone.
     */
    this.ready = new EventEmitter();
  }
  ngOnChanges() {
    if (this.control) {
      console.warn('Control does not support dynamic configuration. You can config it manually using ymaps or recreate the component with new configuration.');
    }
  }
  ngOnInit() {
    this.yaMapComponent.map$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(map => {
      if (!this.type) {
        throw new Error('ymaps.control[type] is invalid.');
      }
      const control = new ymaps.control[this.type](this.parameters);
      this.control = control;
      // RoutePanel ignores state in parameters. API bug
      if (control instanceof ymaps.control.RoutePanel && this.parameters && this.parameters.state) {
        control.routePanel.state.set({
          ...this.parameters.state
        });
      }
      map.controls.add(control);
      this.ready.emit({
        ymaps,
        target: control
      });
    });
  }
  ngOnDestroy() {
    if (this.control) {
      this.yaMapComponent?.map$.value?.controls.remove(this.control);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaControlDirective,
      deps: [{
        token: YaMapComponent
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.0.1",
      type: YaControlDirective,
      selector: "ya-control",
      inputs: {
        type: "type",
        parameters: "parameters"
      },
      outputs: {
        ready: "ready"
      },
      usesOnChanges: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaControlDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ya-control'
    }]
  }],
  ctorParameters: () => [{
    type: YaMapComponent
  }],
  propDecorators: {
    type: [{
      type: Input
    }],
    parameters: [{
      type: Input
    }],
    ready: [{
      type: Output
    }]
  }
});

/**
 * The `ya-multiroute` component wraps `ymaps.multiRouter.MultiRoute` class from the Yandex.Maps API.
 * You can configure it via the component's inputs.
 * Events can be bound using the outputs of the component.
 *
 * ```html
 * <ya-map [center]="[55.761952, 37.620739]">
 *   <ya-multiroute
 *     [referencePoints]="[[55.751952, 37.600739], 'Красные ворота, Москва']"
 *     [models]="{ params: { routingMode: 'pedestrian' } }"
 *   ></ya-multiroute>
 * </ya-map>
 * ```
 */
class YaMultirouteDirective {
  constructor(ngZone, yaMapComponent) {
    this.ngZone = ngZone;
    this.yaMapComponent = yaMapComponent;
    this.destroy$ = new Subject();
    this.eventManager = new EventManager(this.ngZone);
    /**
     * Multi-route reference points.
     * Shorthand for `[models]="{ referencePoints: [0, 0] }"`.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IMultiRouteReferencePoint.html}
     */
    this.referencePoints = [];
    /**
     * Multi-route instance is added to a Map. This event runs outside an Angular zone.
     */
    this.ready = new EventEmitter();
    /**
     * Change to the active route.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/multiRouter.MultiRoute.html#event_detail__event-activeroutechange}
     */
    this.activeroutechange = this.eventManager.getLazyEmitter('activeroutechange');
    /**
     * Closing the balloon.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/multiRouter.MultiRoute.html#event_detail__event-balloonclose}
     */
    this.balloonclose = this.eventManager.getLazyEmitter('balloonclose');
    /**
     * Opening the balloon.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/multiRouter.MultiRoute.html#event_detail__event-balloonopen}
     */
    this.balloonopen = this.eventManager.getLazyEmitter('balloonopen');
    /**
     * The event occurs at the time of setting the map center and its zoom level for optimal display of the multi-route.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/multiRouter.MultiRoute.html#event_detail__event-boundsautoapply}
     */
    this.boundsautoapply = this.eventManager.getLazyEmitter('boundsautoapply');
    /**
     * Changing coordinates of the geographical area covering the multi-route.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/multiRouter.MultiRoute.html#event_detail__event-boundschange}
     */
    this.boundschange = this.eventManager.getLazyEmitter('boundschange');
    /**
     * Single left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-click}
     */
    this.yaclick = this.eventManager.getLazyEmitter('click');
    /**
     * Calls the element's context menu.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-contextmenu}
     */
    this.yacontextmenu = this.eventManager.getLazyEmitter('contextmenu');
    /**
     * Double left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-dblclick}
     */
    this.yadblclick = this.eventManager.getLazyEmitter('dblclick');
    /**
     * Change to the geo object geometry.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-geometrychange}
     */
    this.geometrychange = this.eventManager.getLazyEmitter('geometrychange');
    /**
     * Map reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IParentOnMap.html#event_detail__event-mapchange}
     */
    this.mapchange = this.eventManager.getLazyEmitter('mapchange');
    /**
     * Pressing the mouse button over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousedown}
     */
    this.yamousedown = this.eventManager.getLazyEmitter('mousedown');
    /**
     * Pointing the cursor at the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseenter}
     */
    this.yamouseenter = this.eventManager.getLazyEmitter('mouseenter');
    /**
     * Moving the cursor off of the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseleave}
     */
    this.yamouseleave = this.eventManager.getLazyEmitter('mouseleave');
    /**
     * Moving the cursor over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousemove}
     */
    this.yamousemove = this.eventManager.getLazyEmitter('mousemove');
    /**
     * Letting go of the mouse button over an object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseup}
     */
    this.yamouseup = this.eventManager.getLazyEmitter('mouseup');
    /**
     * End of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchend}
     */
    this.multitouchend = this.eventManager.getLazyEmitter('multitouchend');
    /**
     * Repeating event during multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchmove}
     */
    this.multitouchmove = this.eventManager.getLazyEmitter('multitouchmove');
    /**
     * Start of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchstart}
     */
    this.multitouchstart = this.eventManager.getLazyEmitter('multitouchstart');
    /**
     * Change to the object options.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/ICustomizable.html#event_detail__event-optionschange}
     */
    this.optionschange = this.eventManager.getLazyEmitter('optionschange');
    /**
     * Change to the geo object overlay.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-overlaychange}
     */
    this.overlaychange = this.eventManager.getLazyEmitter('overlaychange');
    /**
     * The parent object reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IChild.html#event_detail__event-parentchange}
     */
    this.parentchange = this.eventManager.getLazyEmitter('parentchange');
    /**
     * Changing pixel coordinates of the area covering the multi-route.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/multiRouter.MultiRoute.html#event_detail__event-pixelboundschange}
     */
    this.pixelboundschange = this.eventManager.getLazyEmitter('pixelboundschange');
    /**
     * Change to the geo object data.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-propertieschange}
     */
    this.propertieschange = this.eventManager.getLazyEmitter('propertieschange');
    /**
     * Updating the multi-route.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/multiRouter.MultiRoute.html#event_detail__event-update}
     */
    this.update = this.eventManager.getLazyEmitter('update');
    /**
     * Mouse wheel scrolling.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-wheel}
     */
    this.yawheel = this.eventManager.getLazyEmitter('wheel');
  }
  /**
   * Handles input changes and passes them in API.
   * @param changes
   */
  ngOnChanges(changes) {
    // It must be run outside a zone; otherwise, all async events within this call will cause ticks.
    this.ngZone.runOutsideAngular(() => {
      const {
        multiroute
      } = this;
      if (multiroute) {
        const {
          referencePoints,
          model,
          options
        } = changes;
        if (model) {
          this.setModel(model.currentValue, multiroute);
        }
        if (referencePoints) {
          multiroute.model.setReferencePoints(referencePoints.currentValue);
        }
        if (options) {
          multiroute.options.set(options.currentValue);
        }
      }
    });
  }
  ngOnInit() {
    this.yaMapComponent.map$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(map => {
      const multiroute = this.createMultiroute();
      this.multiroute = multiroute;
      map.geoObjects.add(multiroute);
      this.eventManager.setTarget(multiroute);
      this.ready.emit({
        ymaps,
        target: multiroute
      });
    });
  }
  ngOnDestroy() {
    if (this.multiroute) {
      this.yaMapComponent?.map$.value?.geoObjects.remove(this.multiroute);
      this.eventManager.destroy();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Destructs state and passes them in API.
   * @param model
   * @param multiroute
   */
  setModel(model, multiroute) {
    const {
      referencePoints,
      params
    } = model;
    if (referencePoints) {
      multiroute.model.setReferencePoints(referencePoints);
    }
    if (params) {
      multiroute.model.setParams(params);
    }
  }
  /**
   * Creates Multiroute.
   */
  createMultiroute() {
    return new ymaps.multiRouter.MultiRoute(this.combineModel(), this.options);
  }
  /**
   * Combines the models and reference points into single object
   */
  combineModel() {
    const model = this.model || {};
    return {
      ...model,
      referencePoints: this.referencePoints || model.referencePoints
    };
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaMultirouteDirective,
      deps: [{
        token: i0.NgZone
      }, {
        token: YaMapComponent
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.0.1",
      type: YaMultirouteDirective,
      selector: "ya-multiroute",
      inputs: {
        referencePoints: "referencePoints",
        model: "model",
        options: "options"
      },
      outputs: {
        ready: "ready",
        activeroutechange: "activeroutechange",
        balloonclose: "balloonclose",
        balloonopen: "balloonopen",
        boundsautoapply: "boundsautoapply",
        boundschange: "boundschange",
        yaclick: "yaclick",
        yacontextmenu: "yacontextmenu",
        yadblclick: "yadblclick",
        geometrychange: "geometrychange",
        mapchange: "mapchange",
        yamousedown: "yamousedown",
        yamouseenter: "yamouseenter",
        yamouseleave: "yamouseleave",
        yamousemove: "yamousemove",
        yamouseup: "yamouseup",
        multitouchend: "multitouchend",
        multitouchmove: "multitouchmove",
        multitouchstart: "multitouchstart",
        optionschange: "optionschange",
        overlaychange: "overlaychange",
        parentchange: "parentchange",
        pixelboundschange: "pixelboundschange",
        propertieschange: "propertieschange",
        update: "update",
        yawheel: "yawheel"
      },
      usesOnChanges: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaMultirouteDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ya-multiroute'
    }]
  }],
  ctorParameters: () => [{
    type: i0.NgZone
  }, {
    type: YaMapComponent
  }],
  propDecorators: {
    referencePoints: [{
      type: Input
    }],
    model: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    activeroutechange: [{
      type: Output
    }],
    balloonclose: [{
      type: Output
    }],
    balloonopen: [{
      type: Output
    }],
    boundsautoapply: [{
      type: Output
    }],
    boundschange: [{
      type: Output
    }],
    yaclick: [{
      type: Output
    }],
    yacontextmenu: [{
      type: Output
    }],
    yadblclick: [{
      type: Output
    }],
    geometrychange: [{
      type: Output
    }],
    mapchange: [{
      type: Output
    }],
    yamousedown: [{
      type: Output
    }],
    yamouseenter: [{
      type: Output
    }],
    yamouseleave: [{
      type: Output
    }],
    yamousemove: [{
      type: Output
    }],
    yamouseup: [{
      type: Output
    }],
    multitouchend: [{
      type: Output
    }],
    multitouchmove: [{
      type: Output
    }],
    multitouchstart: [{
      type: Output
    }],
    optionschange: [{
      type: Output
    }],
    overlaychange: [{
      type: Output
    }],
    parentchange: [{
      type: Output
    }],
    pixelboundschange: [{
      type: Output
    }],
    propertieschange: [{
      type: Output
    }],
    update: [{
      type: Output
    }],
    yawheel: [{
      type: Output
    }]
  }
});

/**
 * The `ya-object-manager` component wraps `ymaps.ObjectManager` class from the Yandex.Maps API.
 * You can configure it via the component's inputs.
 * Events can be bound using the outputs of the component.
 *
 * <example-url>https://stackblitz.com/edit/object-manager?embed=1&view=preview</example-url>
 *
 * ```html
 * <ya-map [center]="[55.761952, 37.620739]">
 *   <ya-object-manager
 *     [options]="{ clusterize: true }"
 *     (ready)="onReady($event)"
 *   ></ya-object-manager>
 * </ya-map>
 * ```
 */
class YaObjectManagerDirective {
  constructor(ngZone, yaMapComponent) {
    this.ngZone = ngZone;
    this.yaMapComponent = yaMapComponent;
    this.destroy$ = new Subject();
    this.eventManager = new EventManager(this.ngZone);
    /**
     * ObjectManager instance is added to a Map. This event runs outside an Angular zone.
     */
    this.ready = new EventEmitter();
    /**
     * Single left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-click}
     */
    this.yaclick = this.eventManager.getLazyEmitter('click');
    /**
     * Calls the element's context menu.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-contextmenu}
     */
    this.yacontextmenu = this.eventManager.getLazyEmitter('contextmenu');
    /**
     * Double left-click on the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-dblclick}
     */
    this.yadblclick = this.eventManager.getLazyEmitter('dblclick');
    /**
     * Change to the geo object geometry.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-geometrychange}
     */
    this.geometrychange = this.eventManager.getLazyEmitter('geometrychange');
    /**
     * Map reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IParentOnMap.html#event_detail__event-mapchange}
     */
    this.mapchange = this.eventManager.getLazyEmitter('mapchange');
    /**
     * Pressing the mouse button over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousedown}
     */
    this.yamousedown = this.eventManager.getLazyEmitter('mousedown');
    /**
     * Pointing the cursor at the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseenter}
     */
    this.yamouseenter = this.eventManager.getLazyEmitter('mouseenter');
    /**
     * Moving the cursor off of the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseleave}
     */
    this.yamouseleave = this.eventManager.getLazyEmitter('mouseleave');
    /**
     * Moving the cursor over the object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mousemove}
     */
    this.yamousemove = this.eventManager.getLazyEmitter('mousemove');
    /**
     * Letting go of the mouse button over an object.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-mouseup}
     */
    this.yamouseup = this.eventManager.getLazyEmitter('mouseup');
    /**
     * End of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchend}
     */
    this.multitouchend = this.eventManager.getLazyEmitter('multitouchend');
    /**
     * Repeating event during multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchmove}
     */
    this.multitouchmove = this.eventManager.getLazyEmitter('multitouchmove');
    /**
     * Start of multitouch.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-multitouchstart}
     */
    this.multitouchstart = this.eventManager.getLazyEmitter('multitouchstart');
    /**
     * Change to the object options.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/ICustomizable.html#event_detail__event-optionschange}
     */
    this.optionschange = this.eventManager.getLazyEmitter('optionschange');
    /**
     * Change to the geo object overlay.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-overlaychange}
     */
    this.overlaychange = this.eventManager.getLazyEmitter('overlaychange');
    /**
     * The parent object reference changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IChild.html#event_detail__event-parentchange}
     */
    this.parentchange = this.eventManager.getLazyEmitter('parentchange');
    /**
     * Change to the geo object data.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IGeoObject.html#event_detail__event-propertieschange}
     */
    this.propertieschange = this.eventManager.getLazyEmitter('propertieschange');
    /**
     * Mouse wheel scrolling.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/IDomEventEmitter.html#event_detail__event-wheel}
     */
    this.yawheel = this.eventManager.getLazyEmitter('wheel');
  }
  /**
   * Handles input changes and passes them in API.
   * @param changes
   */
  ngOnChanges(changes) {
    // It must be run outside a zone; otherwise, all async events within this call will cause ticks.
    this.ngZone.runOutsideAngular(() => {
      const {
        objectManager
      } = this;
      if (objectManager) {
        const {
          options
        } = changes;
        if (options) {
          objectManager.options.set(options.currentValue);
        }
      }
    });
  }
  ngOnInit() {
    this.yaMapComponent.map$.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe(map => {
      const objectManager = this.createObjectManager();
      this.objectManager = objectManager;
      map.geoObjects.add(objectManager);
      this.eventManager.setTarget(objectManager);
      this.ready.emit({
        ymaps,
        target: objectManager
      });
    });
  }
  ngOnDestroy() {
    if (this.objectManager) {
      this.yaMapComponent?.map$.value?.geoObjects.remove(this.objectManager);
      this.eventManager.destroy();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Creates ObjectManager.
   */
  createObjectManager() {
    if (!this.options) {
      throw new Error('ObjectManager options are undefined.');
    }
    return new ymaps.ObjectManager(this.options);
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaObjectManagerDirective,
      deps: [{
        token: i0.NgZone
      }, {
        token: YaMapComponent
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.0.1",
      type: YaObjectManagerDirective,
      selector: "ya-object-manager",
      inputs: {
        options: "options"
      },
      outputs: {
        ready: "ready",
        yaclick: "yaclick",
        yacontextmenu: "yacontextmenu",
        yadblclick: "yadblclick",
        geometrychange: "geometrychange",
        mapchange: "mapchange",
        yamousedown: "yamousedown",
        yamouseenter: "yamouseenter",
        yamouseleave: "yamouseleave",
        yamousemove: "yamousemove",
        yamouseup: "yamouseup",
        multitouchend: "multitouchend",
        multitouchmove: "multitouchmove",
        multitouchstart: "multitouchstart",
        optionschange: "optionschange",
        overlaychange: "overlaychange",
        parentchange: "parentchange",
        propertieschange: "propertieschange",
        yawheel: "yawheel"
      },
      usesOnChanges: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaObjectManagerDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ya-object-manager'
    }]
  }],
  ctorParameters: () => [{
    type: i0.NgZone
  }, {
    type: YaMapComponent
  }],
  propDecorators: {
    options: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    yaclick: [{
      type: Output
    }],
    yacontextmenu: [{
      type: Output
    }],
    yadblclick: [{
      type: Output
    }],
    geometrychange: [{
      type: Output
    }],
    mapchange: [{
      type: Output
    }],
    yamousedown: [{
      type: Output
    }],
    yamouseenter: [{
      type: Output
    }],
    yamouseleave: [{
      type: Output
    }],
    yamousemove: [{
      type: Output
    }],
    yamouseup: [{
      type: Output
    }],
    multitouchend: [{
      type: Output
    }],
    multitouchmove: [{
      type: Output
    }],
    multitouchstart: [{
      type: Output
    }],
    optionschange: [{
      type: Output
    }],
    overlaychange: [{
      type: Output
    }],
    parentchange: [{
      type: Output
    }],
    propertieschange: [{
      type: Output
    }],
    yawheel: [{
      type: Output
    }]
  }
});

/**
 * The `ya-panorama` component wraps `ymaps.panorama.Player` class from the Yandex.Maps API.
 * You can configure it via the component's inputs.
 * Events can be bound using the outputs of the component.
 *
 * ```html
 * <ya-map>
 *   <ya-panorama [point]="[59.938557, 30.316198]" layer="yandex#airPanorama"></ya-panorama>
 * </ya-map>
 * ```
 */
class YaPanoramaDirective {
  constructor(ngZone, yaMapComponent) {
    this.ngZone = ngZone;
    this.yaMapComponent = yaMapComponent;
    this.destroy$ = new Subject();
    this.eventManager = new EventManager(this.ngZone);
    /**
     * The point for searching for nearby panoramas.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.locate.html#panorama.locate__param-point}
     */
    this.point = [];
    /**
     * Panorama instance is created. This event runs outside an Angular zone.
     */
    this.ready = new EventEmitter();
    /**
     * The player was closed by the user or destroyed using the panorama.Player.destroy method.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-destroy}
     */
    this.destroy = this.eventManager.getLazyEmitter('destroy');
    /**
     * The view direction changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-directionchange}
     */
    this.directionchange = this.eventManager.getLazyEmitter('directionchange');
    /**
     * An error occurred during operation of the player. The user will be shown the appropriate screen.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-error}
     */
    this.yaerror = this.eventManager.getLazyEmitter('error');
    /**
     * The panorama player switched to full-screen mode.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-fullscreenenter}
     */
    this.fullscreenenter = this.eventManager.getLazyEmitter('fullscreenenter');
    /**
     * The panorama player exited full-screen mode.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-fullscreenexit}
     */
    this.fullscreenexit = this.eventManager.getLazyEmitter('fullscreenexit');
    /**
     * The user clicked on an expanded marker.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-markercollapse}
     */
    this.markercollapse = this.eventManager.getLazyEmitter('markercollapse');
    /**
     * The user clicked on a collapsed marker.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-markerexpand}
     */
    this.markerexpand = this.eventManager.getLazyEmitter('markerexpand');
    /**
     * The user's cursor hovered over a marker.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-markermouseenter}
     */
    this.markermouseenter = this.eventManager.getLazyEmitter('markermouseenter');
    /**
     * The user's cursor left a marker.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-markermouseleave}
     */
    this.markermouseleave = this.eventManager.getLazyEmitter('markermouseleave');
    /**
     * The open panorama changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-panoramachange}
     */
    this.panoramachange = this.eventManager.getLazyEmitter('panoramachange');
    /**
     * The size of the viewport has been changed.
     * {@link https://yandex.com/dev/maps/jsapi/doc/2.1/ref/reference/panorama.Player.html#event_detail__event-spanchange}
     */
    this.spanchange = this.eventManager.getLazyEmitter('spanchange');
  }
  /**
   * Handles input changes and passes them in API.
   * @param changes
   */
  ngOnChanges(changes) {
    // It must be run outside a zone; otherwise, all async events within this call will cause ticks.
    this.ngZone.runOutsideAngular(() => {
      const {
        player
      } = this;
      if (player) {
        const {
          point,
          layer,
          options
        } = changes;
        /**
         * player.moveTo resets values to default if any of them isn't passed.
         * That's why we use value from currentValue OR previous value from input.
         * With that logic it's possible to pass only point, layer or options.
         */
        if (point || layer) {
          const combinedPoint = point?.currentValue || this.point;
          const combinedLayer = layer?.currentValue || this.layer;
          player.moveTo(combinedPoint, {
            layer: combinedLayer
          });
        }
        if (options) {
          this.setOptions(options.currentValue, player);
        }
      }
    });
  }
  ngOnInit() {
    const panorama$ = this.yaMapComponent.map$.pipe(filter(Boolean), switchMap(map => {
      /**
       * Maps and panoramas use the same container,
       * so we need to destroy/remove this map.
       */
      map.destroy();
      return this.createPanorama();
    }));
    panorama$.pipe(takeUntil(this.destroy$)).subscribe(panorama => {
      const {
        id
      } = this.yaMapComponent.container.nativeElement;
      const player = new ymaps.panorama.Player(id, panorama, this.options);
      /**
       * If the panorama$ is changed, it means that the $map is changed,
       * and finally it means that the configuration is changed, e.g. language,
       * and we need to reinitialize the player.
       */
      if (this.player) {
        this.player.destroy();
      }
      this.player = player;
      /**
       * The API breaks if we call destroy on a destroyed player instance.
       * That's why sync states => local and API.
       */
      this.player.events.add('destroy', () => {
        this.player = undefined;
      });
      this.eventManager.setTarget(player);
      this.ready.emit({
        ymaps,
        target: player
      });
    });
  }
  ngOnDestroy() {
    this.eventManager.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Destructs state and passes it in API.
   * @param options
   * @param player
   */
  setOptions(options, player) {
    const {
      autoFitToViewport,
      controls,
      direction,
      hotkeysEnabled,
      span,
      scrollZoomBehavior,
      suppressMapOpenBlock
    } = options;
    if (autoFitToViewport || controls || hotkeysEnabled || scrollZoomBehavior || suppressMapOpenBlock) {
      console.warn('Only direction and span can be set after entity init. To set other options, you should recreate a Panorama with new options');
    }
    if (direction) {
      player.setDirection(direction);
    }
    if (span) {
      player.setSpan(span);
    }
  }
  /**
   * Searches for a panorama and returns first
   */
  createPanorama() {
    return from(ymaps.panorama.locate(this.point, {
      layer: this.layer
    })).pipe(map(panoramas => panoramas[0]));
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaPanoramaDirective,
      deps: [{
        token: i0.NgZone
      }, {
        token: YaMapComponent
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "18.0.1",
      type: YaPanoramaDirective,
      selector: "ya-panorama",
      inputs: {
        point: "point",
        layer: "layer",
        options: "options"
      },
      outputs: {
        ready: "ready",
        destroy: "destroy",
        directionchange: "directionchange",
        yaerror: "yaerror",
        fullscreenenter: "fullscreenenter",
        fullscreenexit: "fullscreenexit",
        markercollapse: "markercollapse",
        markerexpand: "markerexpand",
        markermouseenter: "markermouseenter",
        markermouseleave: "markermouseleave",
        panoramachange: "panoramachange",
        spanchange: "spanchange"
      },
      usesOnChanges: true,
      ngImport: i0
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaPanoramaDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ya-panorama'
    }]
  }],
  ctorParameters: () => [{
    type: i0.NgZone
  }, {
    type: YaMapComponent
  }],
  propDecorators: {
    point: [{
      type: Input
    }],
    layer: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    ready: [{
      type: Output
    }],
    destroy: [{
      type: Output
    }],
    directionchange: [{
      type: Output
    }],
    yaerror: [{
      type: Output
    }],
    fullscreenenter: [{
      type: Output
    }],
    fullscreenexit: [{
      type: Output
    }],
    markercollapse: [{
      type: Output
    }],
    markerexpand: [{
      type: Output
    }],
    markermouseenter: [{
      type: Output
    }],
    markermouseleave: [{
      type: Output
    }],
    panoramachange: [{
      type: Output
    }],
    spanchange: [{
      type: Output
    }]
  }
});
class AngularYandexMapsModule {
  /**
   * Please use this method when registering the module at the root level.
   * If used in a lazy-loaded module, YaApiLoaderService will not take the provided configuration.
   * @param config configuration for YaApiLoaderService
   */
  static forRoot(config) {
    return {
      ngModule: AngularYandexMapsModule,
      providers: [{
        provide: YA_CONFIG,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: AngularYandexMapsModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: AngularYandexMapsModule,
      declarations: [YaClustererComponent, YaControlDirective, YaGeoObjectDirective, YaMapComponent, YaMultirouteDirective, YaObjectManagerDirective, YaPanoramaDirective, YaPlacemarkDirective],
      exports: [YaClustererComponent, YaControlDirective, YaGeoObjectDirective, YaMapComponent, YaMultirouteDirective, YaObjectManagerDirective, YaPanoramaDirective, YaPlacemarkDirective]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: AngularYandexMapsModule
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: AngularYandexMapsModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [YaClustererComponent, YaControlDirective, YaGeoObjectDirective, YaMapComponent, YaMultirouteDirective, YaObjectManagerDirective, YaPanoramaDirective, YaPlacemarkDirective],
      exports: [YaClustererComponent, YaControlDirective, YaGeoObjectDirective, YaMapComponent, YaMultirouteDirective, YaObjectManagerDirective, YaPanoramaDirective, YaPlacemarkDirective]
    }]
  }]
});

/**
 * The `YaGeocoder` service wraps `ymaps.geocode` static function from the Yandex.Maps API.
 *
 * ```ts
 * import { YaGeocoderService } from 'angular8-yandex-maps';
 *
 * @Component()
 * export class AppComponent {
 *   constructor(private yaGeocoderService: YaGeocoderService) {
 *     // Don't forget to unsubscribe
 *     this.yaGeocoderService.geocode('Moscow')
 *       .subscribe(v => console.log(v))
 *   }
 * }
 * ```
 */
class YaGeocoderService {
  constructor(ngZone, yaApiLoaderService) {
    this.ngZone = ngZone;
    this.yaApiLoaderService = yaApiLoaderService;
  }
  /**
   * Processes geocoding requests.
   * @param request the address for which coordinates need to be obtained (forward geocoding),
   * or the coordinates for which the address needs to be determined (reverse geocoding).
   * @param options geocode options.
   */
  geocode(request, options) {
    return this.yaApiLoaderService.load().pipe(switchMap(() => from(ymaps.geocode(request, options))), enterZone(this.ngZone));
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaGeocoderService,
      deps: [{
        token: i0.NgZone
      }, {
        token: YaApiLoaderService
      }],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "18.0.1",
      ngImport: i0,
      type: YaGeocoderService,
      providedIn: 'root'
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.1",
  ngImport: i0,
  type: YaGeocoderService,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: () => [{
    type: i0.NgZone
  }, {
    type: YaApiLoaderService
  }]
});

/*
 * Public API Surface of angular8-yandex-maps
 */
// Yandex.Maps typings

/**
 * Generated bundle index. Do not edit.
 */

export { AngularYandexMapsModule, YA_CONFIG, YaApiLoaderService, YaClustererComponent, YaControlDirective, YaGeoObjectDirective, YaGeocoderService, YaMapComponent, YaMultirouteDirective, YaObjectManagerDirective, YaPanoramaDirective, YaPlacemarkDirective };
